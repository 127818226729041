.footer{
    background-attachment: fixed;
    position: relative;
    text-align: center;
    line-height: 10px;

    p{
        font-family:"Helvetica Neue";
        font-size: 8px;
    }
    .footer-nav{
        ul{
            padding-left: 0;
            margin-bottom: 0;
        }
    }
}
.footer nav > ul {
  list-style: none;
  font-weight: normal;
    text-align: center;
}
.footer nav > ul > li{
    display: compact;
    padding: 5px 5px;
    margin: 15px 3px;
    line-height: 20px;
    text-align: center;
}
.footer nav > ul a:not(.btn) {
    color: #ffffff;
    display: block;
    margin-bottom: 3px;
    line-height: 1.6;
    opacity: .8;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
}
.footer nav > ul a:not(.btn):hover,
.footer nav > ul a:not(.btn):focus {
  color: #777777;
  opacity: 1;
}
.footer .copyright {
  color: #777777;
  padding: 10px 1px;
  font-size: 10px;
  margin: 15px 0px;
  line-height: 20px;
  text-align: center;
}
.footer .heart{
    color: #EB5E28;
}
.footer{
    height: 200px;
    background-color: black;
    line-height: $line-height;

    &.footer-black{
        h5{
            color: $white-color;
        }
    }

    .links{
        display: inline-block;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            font-weight: $font-weight-bold;

            > li{
                display: inline-block;
                padding-right: 20px;
            }
            > li:last-child{
                padding-right: 0px;
            }

            a:not(.btn){
                color: $font-color;
                display: block;
                font-size: $font-size-h6;
                margin-bottom: 3px;
                &:hover,
                &:focus{
                    color: $default-states-color;
                }
            }

            &.uppercase-links{
                text-transform: uppercase;
            }
            &.stacked-links{
                margin-top: 10px;

                > li{
                    display: flow;
                    line-height: 26px;
                }
                h4{
                    margin-top: 0px;
                }
            }
        }
    }
    .social-area{
        padding: 15px 15px;
        .btn{
            margin-bottom: 3px;
            padding: 8px;
            i{
                margin-right: 0 !important;
                color: #fff;
            }
        }
    }
    hr{
        border-color: $medium-gray;
        border-width: 1px 0 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .copyright{
        color: $brown-font-color;
        font-size: 0.9em;
        height: auto;
        ul > li{
            padding-right: 0px;
        }
    }
    .title{
        color: $default-states-color;
    }
}
